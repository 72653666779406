<template>
  <div>
    <SnackBar :snackMsg="snackMsg" :snackOn="snackOn" :snackTop="true" @close="snackOn = false" />

    <section>
      <v-row no-gutters>
        <v-img id="imgBg" :height="`calc(80vh - ${$vuetify.application.top}px)`" src="@assets/images/Group-486959.png">
          <v-theme-provider dark>
            <v-container>
              <v-row align="center" justify="end" class="white--text mx-auto" style="transform: translateY(40%);">

                <v-col class="white--text text-center" tag="h1">

                  <!-- Title Text -->
                  <span class="custom-font" :style="{ fontSize: '4vw', lineHeight: '1.2', display: 'block' }">
                    {{ $t('landing.newStudy') }} <br>
                    {{ $t('landing.sharingKnowledge') }} <br><br>
                  </span>

                  <!-- Sign Up Button -->
                  <v-btn x-large style="border-radius: 12px;" color="#F285B5" class=" mx-3" to="/signup">
                    <span style="color: #FFFFFF;">
                      {{ $t('tryHiSolver') }}
                    </span>
                  </v-btn>

                  <!-- Sign In Button -->
                  <v-btn x-large style="border-radius: 12px;" color="white" class="mx-3" to="/signin">
                    <span style="color: #BC88FE;">
                      {{ $t('signin') }}
                    </span>
                  </v-btn>

                </v-col>

              </v-row>
            </v-container>

          </v-theme-provider>




        </v-img>

      </v-row>

    </section>



    <!-- NEW SECTION -->
    <section style="background-color: #E9E9E9;">

      <v-responsive class="responsive-video mx-auto" style="position: relative; top: calc(-10vh); z-index: 4;">
        <RoundedPlayer style="max-height:300px;" videoId="demo-video" :url="videoSource" :thumbnail="thumbnail"
          :options="{
      controls: true,
      autoplay: false,
      responsive: true,
      playbackRates: [0.5, 1, 1.5, 2]
    }" :isPreview="false" :mediaViewer="true" />
      </v-responsive>

      <!-- <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <About />
          </v-col>
        </v-row>
      </v-container>

      <v-container class="text-center">
        <div
          class="mb-8 title mx-auto grey--text text--darken-1 font-weight-regular"
          style="max-width: 720px;"
          v-html="$t('landing.description')"
        ></div>

      </v-container> -->


      <v-container class="d-flex align-center justify-center"
        style="position: relative; margin-top: -40px; top: calc(8vh);">

        <!-- The Text Container -->
        <div class="custom-font"
          style="background-color: rgba(255, 255, 255, 0.4); border-radius: 24px; padding: 60px; max-width: 800px; width: 100%; position: relative; z-index: 2; backdrop-filter: blur(10px);">
          <div style="font-size: 30px; font-weight: 500; margin-bottom: 15px; margin-right: 150px;"
            v-html="$t('landing.harvard_hook')">
          </div>
          <div style="font-size: 20px;" v-html="$t('landing.description')">
          </div>
        </div>

        <!-- The Overlapping Image (Relative to the Text Container) -->
        <v-img src="@/assets/images/Star-2-300x300.png"
          style="position: absolute; bottom: 0vw; right: -1vw; width: 28vw; height: 28vw; z-index: 1;"></v-img>

        <!-- Circular Image with Transparent Background (Relative to the Text Container) -->
        <div
          style="position: absolute; top: -2vw; right: 20vw; width: 8vw; height: 8vw; z-index: 3; background-color: rgba(255, 255, 255, 0.4); border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center; padding: 10px;">
          <v-img src="@/assets/images/harvard.png" style="object-fit: contain; width: 100%; height: 100%;"></v-img>
        </div>

      </v-container>




    </section>


    <!-- NEW SECTION -->
    <section style="background-color: #E3E3E3; padding: 0 20px;">
      <br><br><br><br>

      <!-- <Features /> -->

      <div style="transform: scale(0.9);">
        <TabsComponent />
      </div>
    </section>



    <section style="background-color: #E9E9E9; padding: 40px 10px; overflow: hidden;">
      <!-- Community Feedback Section -->
      <v-row justify="center" align="center">
        <!-- Image on the Left -->
        <v-col class="text-center" style="padding: 40px; display: flex; justify-content: flex-end;">
          <v-img src="@/assets/images/Group-487104-1.png" alt="Community Feedback" max-width="60%"
            style="align-self: center;" />
        </v-col>

        <!-- Text on the Right -->
        <v-col style="padding-right: 200px;">
          <span class="custom-font" style="font-size: 2.5rem; font-weight: 400; color: #6D1DD5;">
            {{ $t('landing.shaped_by_you.title') }}
          </span>

          <div class="my-4"></div>

          <v-responsive class="custom-font" style="font-size: 1.2rem;">
            {{ $t('landing.shaped_by_you.content') }}
          </v-responsive>

          <div class="my-4"></div>

          <v-responsive width="30%">
            <v-divider />
          </v-responsive>
        </v-col>
      </v-row>
    </section>





    <!-- NEW SECTION -->
    <section>

      <v-img id="imgBg" height="70vh" src="@assets/images/Group-487090.png">
        <br>

        <v-container justify="center" style="position: relative; padding: 0% 10%;">

          <!-- Testimonial Box -->
          <v-container xl="5" lg="8" sm="10" class="text-center"
            style="background-color: rgba(255, 255, 255, 0.3); border-radius: 16px; padding: 20px; box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); position: relative; z-index: 2; backdrop-filter: blur(10px);">
            <span class="custom-font" style="font-size: 50px; color: white; font-weight: 600;">
              {{ $t('landing.testimonials.title') }}
            </span>

            <div class="custom-font">
              <Testimonials />
            </div>

          </v-container>

          <!-- Background Image 1 (Behind the Testimonial Box) -->
          <v-img src="@/assets/images/Group-487080-1.png"
            style="position: absolute; bottom: -10%; left: 3%; width: 250px; height: 250px; z-index: 1;"></v-img>

          <v-img src="@/assets/images/Group-487079-1.png"
            style="position: absolute; top: 5%; right: 3%; width: 250px; height: 250px; z-index: 1;"></v-img>

        </v-container>



        <!-- <v-row justify="center">
          <v-col cols="6" md="3">
            <v-avatar
              tile
              size="180"
              color="white"
              class="elevation-12 rounded-xl mx-auto d-block"
            >
              <v-img
                src="@assets/images/Hierarchical Solver.jpeg"
                max-height="180"
                max-width="180"
              />
            </v-avatar>
          </v-col>
        </v-row> -->

      </v-img>
    </section>


  </div>
</template>

<script>
import TabsComponent from '@components/Open/web/TabsComponent'
import landingVideo from '@/assets/images/landing_video.mp4';
import { mapMutations } from 'vuex'
import About from '@components/Open/web/About'
import Testimonials from '@components/Open/web/Testimonials'
import Demo from '@components/Open/web/Demo'
import Demo2 from '@components/Open/web/Demo2'
import Features from '@components/Open/web/Features'
import { SnackBar } from '@components/App'
import { RoundedPlayer } from '@components/Video';
import { mdiChevronDown, mdiSend, mdiPlayCircleOutline, mdiShimmer } from '@mdi/js'

export default {
  components: {
    TabsComponent,
    About,
    Testimonials,
    Demo,
    Demo2,
    Features,
    SnackBar,
    RoundedPlayer
  },

  async mounted() {
    if (!navigator.cookieEnabled) {
      this.snackMsg = 'noCookies'
      this.snackOn = true
    }

    // const ipgeolocationAPI = 'https://api.ipstack.com/check?access_key=bfa75029e07899a8448c99947b982e0e'
    // const geolocation = await fetch(ipgeolocationAPI).then(response => response.json())

    // console.log(geolocation, navigator.language)
  },

  data() {
    return {

      thumbnail: require('@/assets/images/landing_video_thumbnail.png'),
      videoSource: landingVideo,
      mdiSend,
      mdiChevronDown,
      mdiPlayCircleOutline,
      mdiShimmer,
      snackOn: false,
      snackMsg: '',
      showVideo: false
    }
  },

  methods: {
    ...mapMutations({
      SET_LANDING_SCROLL: 'setLandingScroll'
    })
  }
}
</script>

<style scoped>
/* Deprecated in Vue 3 */
::v-deep .v-dialog {
  border-radius: 24px !important;
}

.custom-font {
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
}

.responsive-video {
  width: 60%;
  /* Default width */

  /* Media query for smaller screens */
  @media (max-width: 1000px) {
    width: 90%;
    /* Width changes to 90% when screen width is 800px or less */
  }
}
</style>
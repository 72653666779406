<template>
  <div :class="['tabs-container', selectedTabClass]">
    <div class="tabs-heading" role="tablist">
      <button v-for="(tab, index) in tabs" :key="index" :id="'tab-title-' + index" class="tab-title"
        :aria-selected="selectedTab === index" :data-tab-index="index + 1" role="tab"
        :tabindex="selectedTab === index ? 0 : -1" :aria-controls="'tab-content-' + index" @click="selectTab(index)">
        <span class="tab-title-text">{{ $t(`landing.features.${tab.title}`) }}</span>
      </button>
    </div>
    <div class="tabs-content">
      <div v-for="(tab, index) in tabs" :key="index" :id="'tab-content-' + index" role="tabpanel"
        :aria-labelledby="'tab-title-' + index" v-show="selectedTab === index" class="tab-content">
        <div class="content-wrapper">
          <div class="text-content">
            <h2 class="tab-heading">{{ $t(`landing.features.${tab.heading}`) }}</h2>
            <p>{{ $t(`landing.features.${tab.description}`) }}</p>
          </div>
          <div class="image-content">
            <img :src="tab.background1" alt="Background Image 1" class="background-image1" />
            <img :src="tab.background2" alt="Background Image 2" class="background-image2" />
            <img :src="tab.image" alt="Tab Image" class="foreground-image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>


import aif from '@/assets/images/features/AIF.jpeg';
import study from '@/assets/images/features/hotel_vid.gif';

import pdfpicf from '@/assets/images/features/neural.gif';
import pdfpic from '@/assets/images/features/bubble-sort-demo.gif';

import contentf from '@/assets/images/features/contentf.jpeg';
import hallsb from '@/assets/images/features/halls.png';

import socialf from '@/assets/images/features/socialf.png';
import socialb from '@/assets/images/features/socialb.jpg';

import gamef from '@/assets/images/features/gamef.jpg';
import gameb from '@/assets/images/features/gameb.png';

import notebookf from '@/assets/images/features/notebookf.jpg';
import notebookb from '@/assets/images/features/notebookb.jpg';
import tabs_background_shape1 from '@/assets/images/Group-487074-1.png'
import tabs_background_shape2 from '@/assets/images/Group-487075-1.png'

export default {
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          title: "sota_ai.title",
          heading: 'sota_ai.heading',
          description:
            'sota_ai.description',
          image: study,
          background1: tabs_background_shape1,
          background2: tabs_background_shape2,
          backgroundClass: 'bg-chatgpt',
        },
        {
          title: 'fastest_chat.title',
          heading: 'fastest_chat.heading',
          description:
            'fastest_chat.description',
          image: pdfpic,
          background1: tabs_background_shape1,
          background2: tabs_background_shape2,
          backgroundClass: 'bg-curadoria',
        },
        {
          title: 'content_halls.title',
          heading: 'content_halls.heading',
          description:
            'content_halls.description',
          image: hallsb,
          background1: tabs_background_shape1,
          background2: tabs_background_shape2,
          backgroundClass: 'bg-pdfs',
        },
        {
          title: 'social_hub.title',
          heading: 'social_hub.heading',
          description: 'social_hub.description',
          image: socialb,
          background1: tabs_background_shape1,
          background2: tabs_background_shape2,
          backgroundClass: 'bg-comunidade',
        },
        {
          title: 'game_arena.title',
          heading: 'game_arena.heading',
          description: 'game_arena.description',
          image: gameb,
          background1: tabs_background_shape1,
          background2: tabs_background_shape2,
          backgroundClass: 'bg-studies',
        },
        {
          title: 'hisolver_notebook.title',
          heading: 'hisolver_notebook.heading',
          description: 'hisolver_notebook.description',
          image: notebookb,
          background1: tabs_background_shape1,
          background2: tabs_background_shape2,
          backgroundClass: 'bg-studies',
        },
      ],
    };
  },
  computed: {
    selectedTabClass() {
      return this.tabs[this.selectedTab].backgroundClass;
    },
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
    },
  },
};
</script>

<style scoped>
.tabs-container {
  padding: 20px;
  color: #000;
  position: relative;
  margin: auto;
  background-color: transparent;
  max-width: 1200px;
}

.tabs-heading {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.tab-title {
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 16px;
  color: #000;
}

.tab-title:hover,
.tab-title[aria-selected='true'] {
  font-weight: bold;
  color: #5b21b6;
  border-bottom: none;
}

.tabs-content {
  padding: 20px;
  position: relative;
  background-color: transparent;
}

.tab-content {
  display: flex;
  justify-content: center;
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-grow: 1;
  /* Allow the content-wrapper to grow */
}

.text-content,
.image-content {
  flex: 1;
  /* Allow both text and image areas to grow */
  padding: 20px;
}

.text-content {
  max-width: 600px;
}

.tab-heading {
  font-size: 2.5rem;
  color: #5b21b6;
  margin-bottom: 10px;
}

.image-content {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Centers the image inside the container */
  position: relative;
}

.foreground-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  /* Center the image */
  position: relative;
  /* Ensure the GIF stays above the background images */
  z-index: 1;
  border-radius: 15px; /* Rounded edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.foreground-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}


.background-image1,
.background-image2 {
  position: absolute;
  z-index: -1;
  /* Ensure they are behind the GIF */

}

.background-image1 {
  width: 200px;
  /* Adjust size as necessary */
  top: -5%;
  left: -5%;
}

.background-image2 {
  width: 200px;
  /* Adjust size as necessary */
  bottom: -5%;
  right: -5%;
}

/* Ensure the container makes space for the background images */
.image-content {
  min-height: 400px;
  /* Adjust based on the height of your GIF and background images */
}
</style>

<template>
  <div class="testimonials">
    <v-carousel
      height="300"
      hide-delimiter-background
      show-arrows-on-hover
      hide-delimiters
    >
      <v-carousel-item
        v-for="(testimonial, index) in testimonials"
        :key="index"
      >
        <v-row
          no-gutters
          align="center"
        >
          <v-col cols="12">
            <div class="message-bubble">
              <v-icon left size="60" color="white">{{mdiFormatQuoteOpen}}</v-icon>
              <blockquote>{{ $t(testimonial.quote) }}</blockquote>
              <p class="author">{{ testimonial.author }}</p>
              <p class="role">{{ $t(testimonial.role) }}</p>
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { mdiFormatQuoteOpen, mdiAccount } from '@mdi/js'

export default {
  data() {
    return {
      testimonials: [
        {
          quote: "landing.testimonials.tom_zhang.quote",
          author: "Tom Zhang",
          role: "landing.testimonials.tom_zhang.role"
        },
        {
          quote: "landing.testimonials.oscar_abdounur.quote",
          author: "Oscar Abdounur",
          role: "landing.testimonials.oscar_abdounur.role"
        },
        {
          quote: "landing.testimonials.peter_lee.quote",
          author: "Peter Lee",
          role: "landing.testimonials.peter_lee.role"
        }
      ],
      mdiFormatQuoteOpen,
      mdiAccount
    }
  }
}
</script>

<style scoped>
.testimonials {
  background-color: transparent; /* Fully transparent background */
  padding: 2em;
  border-radius: 8px;
  box-shadow: none; /* Remove the shadow */
}

.message-bubble {
  background-color: transparent; /* Fully transparent background */
  padding: 2em;
  border-radius: 25px;
  box-shadow: none; /* Remove the shadow */
  color: white; /* Set text color to white */
}

blockquote {
  font-size: 1.5em;
  line-height: 1.6;
  color: white; /* Set text color to white */
  margin: 0;
}

.author {
  font-size: 1em;
  color: white; /* Set text color to white */
  margin-top: 0.5em;
}

.role {
  font-size: 0.9em;
  color: white; /* Set text color to white */
}
</style>

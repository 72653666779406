<template>
  <section class="features-section">
    <div class="container">
      <div class="row g-4"> <!-- Adjust the horizontal space with g-* class -->
        <!-- Feature Cards Loop -->
        <div v-for="feature in features" :key="feature.title" class="col-md-6 col-sm-12">
          <div class="p-2 feature-card-container"> <!-- Padding around each card -->
            <div @mouseenter="feature.rotated = true" :class="{ 'feature-card': true, 'is-rotated': feature.rotated }">
              <!-- Front Side -->
              <div class="front" :style="{backgroundImage: 'url(' + feature.image + ')'}">
                <div class="overlay"></div>
                <div class="feature-title">
                  {{ $t(`landing.features.${feature.title}`) }}
                </div>                    
              </div>
              <!-- Back Side -->
              <div class="back">
                  <div class="feature-image">
                  <img :src="feature.image2" />
                </div>                  
              </div>
            </div>
          </div>
          <div class="feature-description" :style="{ paddingBottom: '70px' }">
            {{ $t(`landing.features.${feature.description}`) }}
          </div>

        </div>
      </div>
    </div>
  </section>
</template>
  
<script>
 
  import aif from '@/assets/images/features/AIF.jpeg'; 
  import study from '@/assets/images/features/hotel_vid.gif'; 
  
  import pdfpicf from '@/assets/images/features/neural.gif'; 
  import pdfpic from '@/assets/images/features/bubble-sort-demo.gif';

  import contentf from '@/assets/images/features/contentf.jpeg'; 
  import hallsb from '@/assets/images/features/halls.png'; 

  import socialf from '@/assets/images/features/socialf.png'; 
  import socialb from '@/assets/images/features/socialb.jpg'; 

  import gamef from '@/assets/images/features/gamef.jpg'; 
  import gameb from '@/assets/images/features/gameb.png'; 

  import notebookf from '@/assets/images/features/notebookf.jpg'; 
  import notebookb from '@/assets/images/features/notebookb.jpg'; 

  export default {
    data() {
      return {
        rotated: [],
        features: [
          {
            "rotated": false,
            "title": "sota_ai.title",
            "description": "sota_ai.description",
            "image": aif,
            "image2": study
          },
          {
            "rotated": false,
            "title": "fastest_chat.title",
            "description": "fastest_chat.description",
            "image": pdfpicf,
            "image2": pdfpic
          },
          {
            "rotated": false,
            "title": "content_halls.title",
            "description": "content_halls.description",
            "image": contentf,
            "image2": hallsb
          },
          {
            "rotated": false,
            "title": "social_hub.title",
            "description": "social_hub.description",
            "image": socialf,
            "image2": socialb
          },
          {
            "rotated": false,
            "title": "game_arena.title",
            "description": "game_arena.description",
            "image": gamef,
            "image2": gameb
          },
          {
            "rotated": false,
            "title": "hisolver_notebook.title",
            "description": "hisolver_notebook.description",
            "image": notebookf,
            "image2": notebookb
          },
          // ... similar structure for other features ...
        ],

      };
    },
  };
</script>
  
<style scoped>
.features-section {
  padding-bottom: 30px; /* Adding padding to avoid overlap */
}

.container {
  padding: 0px;
}

.feature-card-container {
  perspective: 1000px;
  margin-bottom: 30px; /* Space between the cards vertically */
  margin-left: 10px; /* Horizontal space to the left */
  margin-right: 10px; /* Horizontal space to the right */
}

.feature-card {
  width: 100%;
  height: 350px; /* You can adjust this to fit your content */
  transform-style: preserve-3d;
  transition: transform .3s;
  cursor: pointer; /* Pointer cursor on hover */
}

@media (min-width: 1888px) {
  .feature-card {
    height: 500px;
  }
}

.feature-card.is-rotated {
  transform: rotateY(180deg);
}

/* .feature-card-container:hover .feature-card {
  transform: rotateY(180deg);
} */

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
}

.front {
  /*background: linear-gradient(to right, #4A148C, #BA68C8); /* Gradient background */
  /*color: white; /* Text color */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 8px 18px 0px rgba(0,0,0,0.2); /* Box shadow for a lifted card effect */
  background-size: cover; /* Makes the background image cover the entire card */
  /*position: relative; /* Helps with positioning overlay and title */
}

.overlay {
  background: linear-gradient(to right, rgba(74, 20, 140, 1), rgba(186, 104, 200, .7)); /* Gradient background */
  position: absolute;
  border-radius: 15px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.feature-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: 35px; /* Larger font size for the title */
  margin-bottom: 15px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Text shadow for more depth */
}

.feature-image img {
  width: 100%; /* Smaller image to leave some padding */
  height: auto;
  border-radius: 15px;
  object-fit: cover;
  margin-bottom: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2); /* Image shadow */
}

.back {
  background-color: #fafafa; /* Light background for contrast */
  border-radius: 15px;
  border: 1px solid #e0e0e0; /* Subtle border color */
  padding: 20px;
  transform: rotateY(180deg);
  overflow: auto; /* Scroll if content overflows */
}

.feature-description {
  font-size: 24px;
  line-height: 1.5;
  color: #787878;
  text-align: center; /* Centered text on back */
}

  </style>
  
<template>
  <v-row
    align="center"
    style="height: 670px;"
  >
    <v-col>
      <v-card
        flat
        @mouseenter="stopSlideShow"
        @mouseleave="startSlideShow"
      >
        <v-window
          v-model="window"
          style="min-height: 556px"
        >
          <v-window-item
            v-for="n in [1,2,3,4,5]"
            :key="n"
          >
            <!-- Slide content -->
            <v-container>
              <!-- Slide image -->
              <v-row justify="center" align="center">
                <v-card
                  outlined
                  class="hs-rounded-lg demo-card"
                  elevation="0"
                >
                  <v-img
                    v-if="n === 1"
                    max-width="800"
                    src="@assets/images/screens/feature1.jpg"
                    class="demo-image"
                  ></v-img>
                  <v-img
                    v-if="n === 2"
                    max-width="800"
                    src="@assets/images/screens/feature2.jpg"
                    class="demo-image"
                  ></v-img>
                  <v-img
                    v-if="n === 3"
                    max-width="800"
                    src="@assets/images/screens/feature3.jpg"
                    class="demo-image"
                  ></v-img>
                  <v-img
                    v-if="n === 4"
                    max-width="800"
                    src="@assets/images/screens/feature4.jpg"
                    class="demo-image"
                  ></v-img>
                  <v-img
                    v-if="n === 5"
                    max-width="800"
                    src="@assets/images/screens/feature5.jpg"
                    class="demo-image"
                  ></v-img>
                </v-card>
              </v-row>

              <div class="my-2"></div>

              <!-- Slide description -->
              <v-row justify="center" align="center">
                <v-card
                  outlined
                  rounded="xl"
                  elevation="0"
                  max-width="800"
                  class="demo-description"
                >
                  <v-card-text>
                    <p
                      v-if="n === 1"
                      class="text-body-1 text-fixed-height"
                    >
                      Welcome to the front page of your study hub! Here's where the magic happens. Chat with your friends, share your brilliant thoughts, or dive into the content rooms - all with Anna, your AI study partner, by your side!
                    </p>

                    <p
                      v-if="n === 2"
                      class="text-body-1 text-fixed-height"
                    >
                      Navigate with ease through the interactive Hall - with a multitude of rooms and functionalities, your learning journey just got a whole lot more exciting!
                    </p>

                    <p
                      v-if="n === 3"
                      class="text-body-1 text-fixed-height"
                    >
                      Navigate your files like never before! Make insightful annotations and when you're ready for a deep-dive, head over to Anna's Chamber. Your personalized academic discussion is just a click away.
                    </p>

                    <p
                      v-if="n === 4"
                      class="text-body-1 text-fixed-height"
                    >
                      Capture your thoughts in the Notebook - with a plethora of functionalities, it's your ultimate tool for organized learning and idea exploration!
                    </p>

                    <p
                      v-if="n === 5"
                      class="text-body-1 text-fixed-height"
                    >
                      Engage in seamless conversations, join intriguing rooms, and enjoy an intuitive chat experience designed for today's learners!
                    </p>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-container>
          </v-window-item>
        </v-window>

        <div class="my-4"></div>

        <!-- Slide navigation buttons -->
        <v-card-actions class="justify-center">
          <v-item-group
            v-model="window"
            class="text-center"
            mandatory
          >
            <v-item
              v-for="n of [1,2,3,4,5]"
              :key="n"
              v-slot="{ active, toggle }"
            >    
              <v-btn
                :input-value="active"
                icon
                @click="toggle"
                class="demo-button"
              >
                <v-icon>
                  {{ mdiRecord }}
                </v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiRecord } from '@mdi/js';

export default {
  data() {
    return {
      mdiRecord,
      length: 5,
      window: 0,
      slideInterval: null,
      slideDuration: 5000, // Duration between slide transitions in milliseconds
    };
  },
  mounted() {
    // Start the automatic slide scrolling when the component is mounted
    this.startSlideShow();
  },
  beforeDestroy() {
    // Stop the automatic slide scrolling when the component is about to be destroyed
    this.stopSlideShow();
  },
  methods: {
    startSlideShow() {
      // Set an interval to automatically switch slides
      this.slideInterval = setInterval(() => {
        this.nextSlide();
      }, this.slideDuration);
    },
    stopSlideShow() {
      // Clear the interval to stop the automatic slide scrolling
      clearInterval(this.slideInterval);
    },
    nextSlide() {
      // Increment the window index to move to the next slide
      this.window = (this.window + 1) % this.length;
    },
  },
};
</script>

<style scoped>
.text-fixed-height {
  min-height: 4.5em; /* Adjust this value as needed (1.5em per line * 3 lines) */
}

.demo-card {
  transition: all 0.5s ease-in-out;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.demo-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.demo-image {
  transition: all 0.5s ease-in-out;
}

.demo-image:hover {
  transform: scale(1.15);
}

.demo-description {
  transition: all 0.5s ease-in-out;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.demo-description:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.demo-button {
  transition: all 0.5s ease-in-out;
  color: #FFA119;
}

.demo-button:hover {
  transform: scale(1.2);
  color: #FFA119;
}
</style>
